import { useParams } from 'react-router-dom';
import { Container, Stack, Typography } from '@mui/material'
import Layout from 'components/Layout';
import { dark } from 'themes';

const DATA = {
  "ChIJ9capn1lCXz4RiJHPeCNsmTA": {
    "msg": "You have the strength to overcome any challenge."
  },
  "ChIJFXVnWBkJfpYR2DqSHGABuBE": {
    "msg": "Your potential is limitless—believe in yourself."
  },
  "ChIJNfLfrh0JfpYR54nA9EyIRk8": {
    "msg": "You are worthy of all the good things in life."
  },
  "ChIJSzL2swcJfpYRIfuNlSEVHVA": {
    "msg": "Every day, you are becoming the best version of yourself."
  },
  "ChIJTwDGORwJfpYR3wS2a2yABmM": {
    "msg": "Your efforts are leading you toward success."
  },
  "ChIJY9QfHQhDXz4R2O3u_QlB3FY": {
    "msg": "You bring positivity and light into the world."
  },
  "69": {
    "msg": "It's the funny number."
  },
  "420": {
    "msg": "Uh yeah, it's 4:20 somewhere."
  }
};

const DEFAULT_MSG = "You are amazing!";

export default function Display() {
  const { uid } = useParams();
  const { msg } = DATA[uid] || { msg: DEFAULT_MSG };

  return (
    <Layout theme={dark}>
      <Container sx={{ height: '75vh' }}>
        <Stack
          height="100%"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h2" align="center">
            {msg &&msg}
          </Typography>
        </Stack>
      </Container>
    </Layout>
  );
}
