import { BrowserRouter, Route, Routes } from "react-router-dom";

import Display from "pages/Display";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:uid" element={<Display />} />

        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
